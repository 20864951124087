const state = {
  tags: [],
  cachedViews: []
}

const mutations = {
  pushtags(state,val){
    let result = state.tags.findIndex(item=>item.name === val.name)
    result === -1 ? state.tags.push(val) : '';
    sessionStorage.setItem('tags',JSON.stringify(state.tags))
  },
  deletetags(state,val){
    let result = state.tags.findIndex(item=>item.name === val.name)
    state.tags.splice(result,1)
    sessionStorage.setItem('tags',JSON.stringify(state.tags))
  },

  delete_tagsView(state){
    state.tags = [];
    sessionStorage.removeItem('tags')
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
