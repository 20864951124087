import CryptoJS from 'crypto-js'

export default {
  //时间戳格式化
  getDate(nS) {
    var time = new Date(nS * 1000);
    let date = {
      y: time.getFullYear(),
      m: time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1,
      d: time.getDate() < 10 ? '0' + time.getDate() : time.getDate(),
      h: time.getHours() < 10 ? '0' + time.getHours() : time.getHours(),
      mm: time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes(),
      s: time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds(),
    };
    return date;
  },

  localStorage_set(key, value, expired) {
    /*
       * set 存储方法
       * @ param {String} 	key 键
       * @ param {String} 	value 值，
       * @ param {String} 	expired 过期时间，以天为单位，非必须
       */

    localStorage[key] = JSON.stringify(value);
    if (expired) {
      localStorage[`${key}__expires__`] = Date.now() + 1000 * 60 * 60 * 24 * expired;
    }
    return value;

  },

  localStorage_get(key) {
    /*
      * get 获取方法
      * @ param {String} 	key 键
      * @ param {String} 	expired 存储时为非必须字段，所以有可能取不到，默认为 Date.now+1
      */
    const expired = localStorage[`${key}__expires__`] || Date.now + 1;
    const now = Date.now();

    if (now >= expired) {
      localStorage.removeItem(key);
      return;
    }
    return localStorage[key] ? JSON.parse(localStorage[key]) : localStorage[key];

  },

  localStorage_del(key) {
    const value = localStorage[key];
    delete localStorage[key];
    delete localStorage[`${key}__expires__`];
    return value;

  },


  /*
  * 登录密码加密规则
  * */
  login_encryption(tel, pwd) {
    let result;
    let telArray = [...tel];
    let newPwd = [...pwd];
    let num_1 = telArray[telArray.length - 3];
    let num_2 = telArray[telArray.length - 2];
    let num_3 = telArray[telArray.length - 1];
    newPwd = [num_1, ...pwd, num_3];
    newPwd.splice(4, 0, num_2);
    result = newPwd;
    return result.join('');
  },

  /*
  * 加密
  * */
  encrypt(word) {
    let key = CryptoJS.enc.Latin1.parse("46cc793c53dc451b");
    let iv = CryptoJS.enc.Latin1.parse("46cc793c53dc451b");
    var encrypted = CryptoJS.AES.encrypt(word, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      adding: CryptoJS.pad.ZeroPadding
    });
    return encrypted.toString();
  },

  /**
   * 解密
   */
  decrypt(word) {
    var key = CryptoJS.enc.Latin1.parse("46cc793c53dc451b");
    let iv = CryptoJS.enc.Latin1.parse("46cc793c53dc451b");
    var decrypt = CryptoJS.AES.decrypt(word, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      adding: CryptoJS.pad.ZeroPadding
    });
    return CryptoJS.enc.Latin1.stringify(decrypt).toString();
  }
}
